// @flow

/**
 * The height of the filmstrip in narrow aspect ratio, or width in wide.
 */
export const FILMSTRIP_SIZE = 90;

/**
 * The aspect ratio of a tile in tile view.
 */
export const TILE_ASPECT_RATIO = 16 / 9;

/*export {FILMSTRIP_SIZE, TILE_ASPECT_RATIO};

const FILMSTRIP_SIZE = 90;
let TILE_ASPECT_RATIO = 16/9;
const { userAgent } = navigator;
if (userAgent.match(/iP(ad|hone|od)/i) || userAgent.match(/Android/i)) {
TILE_ASPECT_RATIO = 3/4;
} else {
TILE_ASPECT_RATIO = 16 / 9;
}*/
